import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import bolt from "../../images/bolt_icon.png";

// importing the css folder
import "./welcome.css";

export default class Welcome extends Component {
  render() {
    return (
      <div className="div_main">
        <Container>
          <Row>
            <Col sm>
              <h2 className="title">
                <span id="title_head">EV</span>
                <span id="title_tail">on</span>
                <img id="bolt_image" src={bolt} alt="" />
              </h2>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <h3 className="desc">Redefining EV Chargining Stations</h3>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
