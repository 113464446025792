import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import evon_logo from "../../images/EVon_text_trans_small.png";

import founder from "../../images/founders/founder.png";
import male from "../../images/founders/male_profile.jpeg";

import "./footer.css";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer_main">
        <Container>
          <Row>
            <Col lg="10" md="9">
              <Container fluid>
                <Row id="founder_title">CEO & FOUNDER</Row>
                <Row>
                  <Col id="founder_image_col" xs="auto">
                    <Image id="founders_image" src={founder} roundedCircle />
                  </Col>
                  <Col xs="auto">
                    <Row id="founder_name"> Dileep Kumar Sharma </Row>
                    <Row>
                      <Col>
                        <a
                          href="https://www.linkedin.com/in/Dileep2896/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faLinkedin} inverse />
                        </a>
                      </Col>
                      <Col>
                        <a
                          href="https://twitter.com/Dileep_Sharma_A"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faTwitter} inverse />
                        </a>
                      </Col>
                      <Col>
                        <a
                          href="mailto:dileepkumar@evonenergy.co.in"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faEnvelope} inverse />
                        </a>
                      </Col>
                      <Col>
                        <a
                          href="https://github.com/Dileep2896"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faGithub} inverse />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row id="co_founder_title">CTO & CO-FOUNDER</Row>
                <Row>
                  <Col id="founder_image_col" xs="auto">
                    <Image id="founders_image" src={male} roundedCircle />
                  </Col>
                  <Col xs="auto">
                    <Row id="founder_name"> Harsha Vardhan B K </Row>
                    <Row>
                      <Col>
                        <a href="/" target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faLinkedin} inverse />
                        </a>
                      </Col>
                      <Col>
                        <a href="/" target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faTwitter} inverse />
                        </a>
                      </Col>
                      <Col>
                        <a
                          href="mailto:harshavardhan.bk@evonenergy.co.in"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon icon={faEnvelope} inverse />
                        </a>
                      </Col>
                      <Col>
                        <a href="/" target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faGithub} inverse />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col className="easy_access" lg="2" md="3">
              <Row>
                <a href="/" id="easy_access_links">
                  {" "}
                  Who are we ?{" "}
                </a>
              </Row>
              <Row>
                <a href="/" id="easy_access_links">
                  {" "}
                  What we do ?{" "}
                </a>
              </Row>
              <Row>
                <a href="/" id="easy_access_links">
                  {" "}
                  How we do ?{" "}
                </a>
              </Row>
              <Row id="contact_row">CONTACT US</Row>
              <Row>
                <a id="contact_mail" href="mailto:contact@evonenergy.co.in">
                  contact@evonenergy.co.in
                </a>
              </Row>
              <Row>
                <a
                  id="contact_add"
                  target="_blank"
                  rel="noreferrer"
                  href="https://goo.gl/maps/81oCDCUNaV1gPKzh7"
                >
                  REVA University, Bangalore Karanataka
                </a>
              </Row>
            </Col>
          </Row>
          <Row className="last_row">
            <Col sm>
              <img id="footer_logo" src={evon_logo} alt="" />
            </Col>
            <Col id="footer_lience_col" sm>
              <p id="footer_licence">© 2022 EVon</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
