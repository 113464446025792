import React from "react";
import "./how.css";
import first from "../../images/home.png";
import second from "../../images/All-In-One.png";
import third from "../../images/Multi-Connect.png";

const HOW = () => {
  return (
    <div className="how_main">
      <div className="how_heading">HOW DO WE DO?</div>
      <div className="how_content">
        <div className="how_first">
          <div className="first-img">
            <img height="100" src={first} alt="Home-Chargers" />
          </div>
          <div className="first">
            <h4>HOME CHARGERS</h4>
            <div className="first-points">
              <ul>
                <li>
                  Providing an option of home charging, where a user can charge
                  at any registered house charging station.
                </li>
                <li>0 Investment Charging Station.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="how_second">
          <div className="second-img">
            <img height="150" width="150" src={second} alt="Home-Chargers" />
          </div>
          <div className="second">
            <h4>ALL-IN-ONE SEARCH</h4>
            <div className="second-points">
              <ul>
                <li>
                  Locate Fast Charges With Our All In One Application
                  &#40;ather, ola, bounce chargers, etc&#41;
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="how_third">
          <div className="third-img">
            <img height="120" src={third} alt="Fast Charger Installation" />
          </div>
          <div className="third">
            <h4>FAST CHARGER INSTALLATION</h4>
            <div className="third-points">
              <ul>
                <li>
                  Helping Required Places To Install Charges &#40;Cafe, Malls,
                  Universities, etc...&#41;
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOW;
