import React, { Component } from "react";

import "./who.css";

export default class WHO extends Component {
  render() {
    return <><div className="who_main">
    <div className="who_heading" > WHO ARE WE? <br/> 

    <div className="who_content"> EVon is a redefined solution to solve the exsisting EV charging infrastructure problems.<br/>
    We work towards connecting charging stations with EV owners.
    
   </div>
   </div>
   </div></>
    
  }
}