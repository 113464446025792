import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import look from "../../images/app_img/look.png";
import book from "../../images/app_img/book.png";
import hook from "../../images/app_img/hook.png";

import "./app.css";

export default class ApplicationDemo extends Component {
  render() {
    return (
      <div className="app_demo_main">
        <Container>
          <Row>
            <Col sm>
              <Row className="app_image">
                <img src={look} alt="" />
              </Row>
              <Row>
                <p className="app_name">
                  <span id="l_name">L</span>OOK{" "}
                </p>
              </Row>
            </Col>
            <Col sm>
              <Row className="app_image">
                <img src={book} alt="" />
              </Row>
              <Row>
                <p className="app_name">
                  <span id="b_name">B</span>OOK
                </p>
              </Row>
            </Col>
            <Col sm>
              <Row className="app_image">
                <img src={hook} alt="" />
              </Row>
              <Row>
                <p className="app_name">
                  <span id="h_name">H</span>OOK
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
