import Navbar from "./components/navbar/navbar.js";
import Welcome from "./components/welcome_section/welcome.js";
import Footer from "./components/footer_section/footer.js";
import AppDemo from "./components/application_demo_section/app.js";
import WHO from "./components/who_section/who.js";
// import WHAT from "./components/what_section/what.js";
import HOW from "./components/how_section/how.js";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Welcome />
      <AppDemo />
      <WHO />
      {/* <WHAT /> */}
      <HOW />
      <Footer />
    </div>
  );
}

export default App;
